export const DEFAULT_SETTINGS = {
  ViewType: 'dashboard',
  theme: '',
  dashboardId: '',
  reportId: '',
  scorecardId: '',
  metricSetId: '',
  toolbarHidden: false,
  toolbarAddMetricsSetHidden: false,
  toolbarAddDashboardHidden: false,
  toolbarAddReportHidden: false,
  dockablesHidden: false,
  openExistingHidden: false,

  themeComposer: '__platform__',
  ip: 'interactive',
  editorPlacement: 'modals',
  visualEditorPlacement: 'dockRight',
  inventoryItem: 'dashboard',
  showTitle: false,
  dashboardShowTitle: false,
  embedInLiteMode: false,
  doubleDashboards: false,
  visualShowTitle: false,
  breadcrumbs: false,
  showActions: false,
  showHeader: false,
  dashboardIpOverrides: false,
  dashboardBuilderIpOverrides: false,
  inventoryIpOverrides: false,
  visualIpOverrides: false,
  listDashboards: false,
  listVisuals: false,
  crossVisualFiltersPublish: false,
  crossVisualFiltersSubscribe: false,
  dashboardEventListeners: false,
  dbEventListener: false,
  dashboardReadyEventListener: false,
  dashboardDeleteEventListener: false,
  dashboardLoadFailEventListener: false,
  dashboardSaveEventListener: false,
  dashboardVisualAddEventListener: false,
  dashboardVisualRemoveEventListener: false,
  dashboardBuilderReadyEventListener: false,
  dashboardBuilderChangedEventListener: false,
  dashboardBuilderDeleteEventListener: false,
  dashboardBuilderLoadFailEventListener: false,
  dashboardBuilderSaveEventListener: false,
  dashboardBuilderVisualAddEventListener: false,
  dashboardBuilderVisualRemoveEventListener: false,
  visualLoadFailEventListener: false,
  visualRenderEventListener: false,
  visualSaveEventListener: false,
  dashboardBuilderVisualLoadEventListener: false,
  dashboardBuilderVisualLoadFailEventListener: false,
  dashboardBuilderVisualRenderEventListener: false,
  dashboardBuilderVisualSaveEventListener: false,
  dashboardChangedEventListener: false,
  visualLoadEventListener: false,
  documentFailed: false,
  dashboardBuilderDocumentFailed: false,
  dashboardBuilderUnauthorized: false,
  unauthorized: false,
  visualBuilderChange: false,
  visualBuilderFail: false,
  visualBuilderLoad: false,
  visualBuilderReady: false,
  dbVisualBuilderChange: false,
  dbVisualBuilderFail: false,
  dbVisualBuilderLoad: false,
  dbVisualBuilderReady: false,
  inventoryEventListeners: false,
  inventoryReadyEventListener: false,
  inventoryFailEventListener: false,
  inventoryDeleteEventListener: false,
  dashboardBuilderEventListeners: false,
  visualEventListener: false,
  vbVisualLoadEventListener: false,
  vbVisualLoadFailEventListener: false,
  vbVisualRenderEventListener: false,
  vbVisualSaveEventListener: false,
  vbVisualBuilderChange: false,
  vbVisualBuilderFail: false,
  vbVisualBuilderReady: false,
  customizeActionButtons: false,
  customizeSearchControllers: false,
  customizeFilterButtons: false,
  customizeInventoryTable: false,
  sourceEditorActiveTab: 'create',
  showCreateTab: true,
  showFieldsTab: true,
  showCacheTab: true,
  showSettingsTab: true,
  sourceEditorEventListeners: false,
  sourceEditorIpOverrides: false,
};

export const DEFAULT_INTERACTIVE_SETTINGS = {
  refresh: true,
  changeLayout: true,
  rename: true,
  shareFilterSets: true,
  dashboardInteractions: true,
  addToFavourites: true,
  delete: true,
  filter: true,
  exportPngPdf: true,
  addVisuals: true,
  exportConfiguration: true,
  dashboardLinks: true,
  save: true,
  actionsAction: true,
  rulers: true,
  zoomAction: true,
  filterAction: true,
  colors: true,
  metrics: true,
  actions: true,
  trendAction: true,
  visualStyle: true,
  keysetAction: true,
  keyset: true,
  copy: true,
  settings: true,
  export: true,
  timebarPanel: true,
  detailsAction: true,
  maximize: true,
  linkAction: true,
  remove: true,
  grouping: true,
  sort: true,
  timebarField: true,
  addNew: true,
  import: true,
  permissions: true,
  schedule: true,
  favorites: true,
  addFromConnection: true,
  addFromFileUpload: true,
  selectFileUpload: true,
  uploadnewFile: true,
  createJoins: true,
  filterValuesEntities: true,
  addDerivedField: true,
  addHierarchyField: true,
  addCustomMetric: true,
  visibility: true,
  fieldCapabilities: true,
  filterValueOverrides: true,
  dataCache: true,
  scheduleRefresh: true,
  statisticsCache: true,
};

export const DEFAULT_READONLY_SETTINGS = {
  refresh: false,
  changeLayout: false,
  rename: false,
  shareFilterSets: false,
  dashboardInteractions: false,
  addToFavourites: false,
  delete: false,
  filter: false,
  exportPngPdf: false,
  addVisuals: false,
  exportConfiguration: false,
  dashboardLinks: false,
  save: false,
  actionsAction: false,
  rulers: false,
  zoomAction: false,
  filterAction: false,
  colors: false,
  metrics: false,
  actions: false,
  trendAction: false,
  visualStyle: false,
  keysetAction: false,
  keyset: false,
  copy: false,
  settings: false,
  export: false,
  timebarPanel: false,
  detailsAction: false,
  maximize: false,
  linkAction: false,
  remove: false,
  grouping: false,
  sort: false,
  timebarField: false,
  addNew: false,
  import: false,
  permissions: false,
  schedule: false,
  favorites: false,
};
