import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useNotificationBanners from 'src/hooks/useNotificationBanners';
import {
  COMMON_NAV_MENU_ITEMS,
  VDD_NAV_MENU_ITEMS,
  MDR_NAV_MENU_ITEMS,
} from 'src/shared/constants/navigationConstants';
import HeaderActions from '../HeaderActions/HeaderActions';
import ModalContainer from '../ModalContainer/ModalContainer';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import styles from './Layout.module.scss';
import { PlatformSidebar } from '../PlatformSidebar/PlatformSidebar';

export default function Layout({ children }) {
  const history = useHistory();
  const [pageTitle, setPageTitle] = useState('');
  const [headerTitle, setHeaderTitle] = useState('');
  const NAV_MENU_ITEMS = [...COMMON_NAV_MENU_ITEMS, ...VDD_NAV_MENU_ITEMS, ...MDR_NAV_MENU_ITEMS];
  const setTitle = (pathname) => {
    setPageTitle(NAV_MENU_ITEMS.find(({ path }) => path === pathname)?.pageTitle || '');
    setHeaderTitle(NAV_MENU_ITEMS.find(({ path }) => path === pathname)?.headerTitle || 'Symphony Playground');
  };
  const { currentBanner, isBannerShown, hideBanner } = useNotificationBanners();
  // const { isTutorialAvailable, currentTutorialItem, getNextTutorialItem } = useTutorial();

  useEffect(() => {
    history.listen(({ pathname }) => {
      setTitle(pathname);
    });
    setTitle(history.location.pathname);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1 className={styles.pageTitle}>
          {headerTitle} {pageTitle && <span className={styles.navigationDelimiter}>&gt;</span>}
          &nbsp;
          <b className={styles.navigationTitle}>{pageTitle}</b>
        </h1>
        <HeaderActions></HeaderActions>
        <img
          src="images/LogiSymphonyPlayground.png"
          alt="isw-logo"
          className={styles.logo}
          onClick={() => history.push('/')}
        />
      </div>
      <div className={styles.navMenu}>
        <PlatformSidebar />
      </div>
      <div className={styles.content}>
        <NotificationBanner
          onClose={hideBanner}
          isVisible={isBannerShown}
          headerText={currentBanner ? currentBanner.bannerText : ''}
        >
          {currentBanner ? currentBanner.bannerComponent : <></>}
        </NotificationBanner>
        {children}
      </div>
      <ModalContainer></ModalContainer>
    </div>
  );
}
