export const OPTIONS = {
  dashboard: {
    basicOptions: [
      { key: 'ip', defaultValue: 'interactive' },
      { key: 'dashboardIpOverrides', defaultValue: true },
    ],
    advancedOptions: [],
    interactivityOptions: {
      Dashboard: [
        { key: 'refresh', defaultValue: false },
        { key: 'changeLayout', defaultValue: false },
        { key: 'rename', defaultValue: false },
        { key: 'dashboardInteractions', defaultValue: false },
        { key: 'addToFavourites', defaultValue: false },
        { key: 'exportPngPdf', defaultValue: false },
        { key: 'dashboardLinks', defaultValue: false },
        { key: 'exportConfiguration', defaultValue: false },
        { key: 'addVisuals', defaultValue: false },
        { key: 'delete', defaultValue: false },
        { key: 'save', defaultValue: false },
        { key: 'filter', defaultValue: false },
      ],
      Visuals: [
        { key: 'actionsAction', defaultValue: false },
        { key: 'rulers', defaultValue: false },
        { key: 'zoomAction', defaultValue: false },
        { key: 'filterAction', defaultValue: false },
        { key: 'colors', defaultValue: false },
        { key: 'metrics', defaultValue: false },
        { key: 'actions', defaultValue: false },
        { key: 'trendAction', defaultValue: false },
        { key: 'visualStyle', defaultValue: false },
        { key: 'keysetAction', defaultValue: false },
        { key: 'keyset', defaultValue: false },
        { key: 'copy', defaultValue: false },
        { key: 'export', defaultValue: false },
        { key: 'timebarPanel', defaultValue: false },
        { key: 'detailsAction', defaultValue: false },
        { key: 'maximize', defaultValue: false },
        { key: 'linkAction', defaultValue: false },
        { key: 'remove', defaultValue: false },
        { key: 'grouping', defaultValue: false },
        { key: 'sort', defaultValue: false },
        { key: 'timebarField', defaultValue: false },
      ],
    },
  },
  'visual-builder': {
    basicOptions: [{ key: 'visualIpOverrides', defaultValue: true }],
    advancedOptions: [],
    interactivityOptions: {
      Visuals: [
        { key: 'actionsAction', defaultValue: false },
        { key: 'rulers', defaultValue: false },
        { key: 'zoomAction', defaultValue: false },
        { key: 'filterAction', defaultValue: false },
        { key: 'colors', defaultValue: false },
        { key: 'metrics', defaultValue: false },
        { key: 'actions', defaultValue: false },
        { key: 'trendAction', defaultValue: false },
        { key: 'visualStyle', defaultValue: false },
        { key: 'keysetAction', defaultValue: false },
        { key: 'keyset', defaultValue: false },
        { key: 'copy', defaultValue: false },
        { key: 'settings', defaultValue: false },
        { key: 'export', defaultValue: false },
        { key: 'timebarPanel', defaultValue: false },
        { key: 'detailsAction', defaultValue: false },
        { key: 'maximize', defaultValue: false },
        { key: 'linkAction', defaultValue: false },
        { key: 'remove', defaultValue: false },
        { key: 'grouping', defaultValue: false },
        { key: 'rename', defaultValue: false },
        { key: 'sort', defaultValue: false },
        { key: 'timebarField', defaultValue: false },
      ],
    },
  },
  'dashboard-builder': {
    basicOptions: [{ key: 'dashboardBuilderIpOverrides', defaultValue: true }],
    advancedOptions: [],
    interactivityOptions: {
      Dashboard: [
        { key: 'refresh', defaultValue: false },
        { key: 'changeLayout', defaultValue: false },
        { key: 'rename', defaultValue: false },
        { key: 'shareFilterSets', defaultValue: false },
        { key: 'dashboardInteractions', defaultValue: false },
        { key: 'addToFavourites', defaultValue: false },
        { key: 'exportPngPdf', defaultValue: false },
        { key: 'dashboardLinks', defaultValue: false },
        { key: 'exportConfiguration', defaultValue: false },
        { key: 'addVisuals', defaultValue: false },
        { key: 'delete', defaultValue: false },
        { key: 'save', defaultValue: false },
        { key: 'filter', defaultValue: false },
      ],
      Visuals: [
        { key: 'actionsAction', defaultValue: false },
        { key: 'rulers', defaultValue: false },
        { key: 'zoomAction', defaultValue: false },
        { key: 'filterAction', defaultValue: false },
        { key: 'colors', defaultValue: false },
        { key: 'metrics', defaultValue: false },
        { key: 'actions', defaultValue: false },
        { key: 'trendAction', defaultValue: false },
        { key: 'visualStyle', defaultValue: false },
        { key: 'keysetAction', defaultValue: false },
        { key: 'keyset', defaultValue: false },
        { key: 'copy', defaultValue: false },
        { key: 'export', defaultValue: false },
        { key: 'timebarPanel', defaultValue: false },
        { key: 'detailsAction', defaultValue: false },
        { key: 'maximize', defaultValue: false },
        { key: 'linkAction', defaultValue: false },
        { key: 'remove', defaultValue: false },
        { key: 'grouping', defaultValue: false },
        { key: 'sort', defaultValue: false },
        { key: 'timebarField', defaultValue: false },
      ],
    },
  },
  inventory: {
    basicOptions: [{ key: 'inventoryIpOverrides', defaultValue: true }],
    advancedOptions: [],
    interactivityOptions: {
      Library: [
        { key: 'addNew', defaultValue: false },
        { key: 'import', defaultValue: false },
        { key: 'filter', defaultValue: false },
        { key: 'delete', defaultValue: false },
        { key: 'permissions', defaultValue: false },
        { key: 'schedule', defaultValue: false },
        { key: 'favorites', defaultValue: false },
      ],
    },
  },
  'source-editor': {
    basicOptions: [{ key: 'sourceEditorIpOverrides', defaultValue: true }],
    advancedOptions: [],
    interactivityOptions: {
      Create: [
        { key: 'addFromConnection', defaultValue: true },
        { key: 'addFromFileUpload', defaultValue: true },
        { key: 'selectFileUpload', defaultValue: true },
        { key: 'uploadnewFile', defaultValue: true },
        { key: 'createJoins', defaultValue: true },
        { key: 'filterValuesEntities', defaultValue: true },
      ],
      Fields: [
        { key: 'addDerivedField', defaultValue: true },
        { key: 'addHierarchyField', defaultValue: true },
        { key: 'addCustomMetric', defaultValue: true },
        { key: 'visibility', defaultValue: true },
        { key: 'settings', defaultValue: true },
        { key: 'delete', defaultValue: true },
        { key: 'filterValueOverrides', defaultValue: true },
        { key: 'fieldCapabilities', defaultValue: true },
      ],
      Caching: [
        { key: 'dataCache', defaultValue: true },
        { key: 'statisticsCache', defaultValue: true },
        { key: 'scheduleRefresh', defaultValue: true },
      ],
    },
  },
};
