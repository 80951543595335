export const DASHBOARD_IP_OVERRIDES_SETTING = `
    // START: Interactivity profile overrides
    // The interactiveProfileName property must be specified before
    // the interactivity overrides (interactivityOverrides object)
    // can work. The interactivityOverrides object properties affect 
    // the current embedded dashboard properties.  The visualSettings 
    // object properties affect all visuals properties. Please read the
    // Documentation Helper for more properties that can be overridden.
    "interactivityOverrides": {
        "name": "interactive",
        "type": "SYSTEM",
        "overrideVisualInteractivity": true,
        "settings": {
            "REFRESH": %refresh%,
            "CHANGE_LAYOUT": %changeLayout%,
            "RENAME": %rename%,
            "SHARE_FILTER_SETS": %shareFilterSets%,
            "DASHBOARD_INTERACTIONS": %dashboardInteractions%,
            "ADD_TO_FAVORITES": %addToFavourites%,
            "DELETE": %delete%,
            "FILTER": %filter%,
            "EXPORT_PNG_PDF": %exportPngPdf%,
            "ADD_VISUALS": %addVisuals%,
            "EXPORT_CONFIGURATION": %exportConfiguration%,
            "DASHBOARD_LINKS": %dashboardLinks%,
            "SAVE": %save%
        },
        "visualSettings": {
            "ACTIONS_ACTION": %actionsAction%,
            "RULERS": %rulers%,
            "ZOOM_ACTION": %zoomAction%,
            "FILTER_ACTION": %filterAction%,
            "COLORS": %colors%,
            "METRICS": %metrics%,
            "ACTIONS": %actions%,
            "TREND_ACTION": %trendAction%,
            "VISUAL_STYLE": %visualStyle%,
            "KEYSET_ACTION": %keysetAction%,
            "KEYSET": %keySet%,
            "COPY": %copy%,
            "SETTINGS": %settings%,
            "EXPORT": %export%,
            "TIMEBAR_PANEL": %timebarPanel%,
            "DETAILS_ACTION": %detailsAction%,
            "MAXIMIZE": %maximize%,
            "LINK_ACTION": %linkAction%,
            "FILTER": %filter%,
            "REMOVE": %remove%,
            "GROUPING": %grouping%,
            "RENAME": %rename%,
            "SORT": %sort%,
            "TIMEBAR_FIELD": %timebarField%
        }
    },
    /* END: interactivity profile overrides */`;

export const VISUAL_BUILDER_IP_OVERRIDES_SETTING = `
    // START: Interactivity profile overrides
    // The interactiveProfileName property must be specified before
    // the interactivity overrides (interactivityOverrides object)
    // can work. The interactivityOverrides object properties affect 
    // the current embedded visual properties.  The visualSettings 
    // object properties affect all visuals properties. Please read the
    // Documentation Helper for more properties that can be overridden.
    "interactivityOverrides": {
        "visualSettings": {
            "ACTIONS_ACTION": %actionsAction%,
            "RULERS": %rulers%,
            "ZOOM_ACTION": %zoomAction%,
            "FILTER_ACTION": %filterAction%,
            "COLORS": %colors%,
            "METRICS": %metrics%,
            "ACTIONS": %actions%,
            "TREND_ACTION": %trendAction%,
            "VISUAL_STYLE": %visualStyle%,
            "KEYSET_ACTION": %keysetAction%,
            "KEYSET": %keySet%,
            "COPY": %copy%,
            "SETTINGS": %settings%,
            "EXPORT": %export%,
            "TIMEBAR_PANEL": %timebarPanel%,
            "DETAILS_ACTION": %detailsAction%,
            "MAXIMIZE": %maximize%,
            "LINK_ACTION": %linkAction%,
            "FILTER": %filter%,
            "REMOVE": %remove%,
            "GROUPING": %grouping%,
            "RENAME": %rename%,
            "SORT": %sort%,
            "TIMEBAR_FIELD": %timebarField%
        }
    },
    /* END: interactivity profile overrides */`;

export const DASHBOARD_BUILDER_IP_OVERRIDES_SETTING = `
    // START: Interactivity profile overrides
    // The interactiveProfileName property must be specified before
    // the interactivity overrides (interactivityOverrides object)
    // can work. The interactivityOverrides object properties affect 
    // the current embedded visual properties.  The visualSettings 
    // object properties affect all visuals properties. Please read the
    // Documentation Helper for more properties that can be overridden.
    "interactivityOverrides": {
        "name": "interactive",
        "type": "SYSTEM",
        "overrideVisualInteractivity": true,
        "settings": {
            "REFRESH": %refresh%,
            "CHANGE_LAYOUT": %changeLayout%,
            "RENAME": %rename%,
            "SHARE_FILTER_SETS": %shareFilterSets%,
            "DASHBOARD_INTERACTIONS": %dashboardInteractions%,
            "ADD_TO_FAVORITES": %addToFavourites%,
            "DELETE": %delete%,
            "FILTER": %filter%,
            "EXPORT_PNG_PDF": %exportPngPdf%,
            "ADD_VISUALS": %addVisuals%,
            "EXPORT_CONFIGURATION": %exportConfiguration%,
            "DASHBOARD_LINKS": %dashboardLinks%,
            "SAVE": %save%
        },
        "visualSettings": {
            "ACTIONS_ACTION": %actionsAction%,
            "RULERS": %rulers%,
            "ZOOM_ACTION": %zoomAction%,
            "FILTER_ACTION": %filterAction%,
            "COLORS": %colors%,
            "METRICS": %metrics%,
            "ACTIONS": %actions%,
            "TREND_ACTION": %trendAction%,
            "VISUAL_STYLE": %visualStyle%,
            "KEYSET_ACTION": %keysetAction%,
            "KEYSET": %keySet%,
            "COPY": %copy%,
            "SETTINGS": %settings%,
            "EXPORT": %export%,
            "TIMEBAR_PANEL": %timebarPanel%,
            "DETAILS_ACTION": %detailsAction%,
            "MAXIMIZE": %maximize%,
            "LINK_ACTION": %linkAction%,
            "FILTER": %filter%,
            "REMOVE": %remove%,
            "GROUPING": %grouping%,
            "RENAME": %rename%,
            "SORT": %sort%,
            "TIMEBAR_FIELD": %timebarField%
        }
    },
    /* END: interactivity profile overrides */`;

export const IVENTORY_IP_OVERRIDES_SETTING = `
    // START: Interactivity profile overrides
    // The interactiveProfileName property must be specified before
    // the interactivity overrides (interactivityOverrides object)
    // can work. The interactivityOverrides object properties affect 
    // the current embedded visual properties.  The visualSettings 
    // object properties affect all visuals properties. Please read the
    // Documentation Helper for more properties that can be overridden.
    "interactivityOverrides": {
        "name": "interactive",
        "type": "SYSTEM",
        "overrideVisualInteractivity": true,
        "settings": {
            "DELETE": %delete%,
            "FILTER": %filter%,
            "IMPORT": %import%,
            "ADD_NEW": %addNew%,
            "PERMISSIONS": %permissions%,
            "SCHEDULE": %schedule%,
            "FAVORITES": %favorites%,
        }
    },
    /* END: interactivity profile overrides */`;

export const SOURCE_EDITOR_IP_OVERRIDES_SETTING = `
    // START: Source Editor profile overrides
    // The interactiveProfileName property must be specified before
    // the interactivity overrides (interactivityOverrides object)
    // can work. The interactivityOverrides object properties affect 
    // the current embedded visual properties.  The visualSettings 
    // object properties affect all source editor properties. Please read the
    // Documentation Helper for more properties that can be overridden.
    "interactivityOverrides": {
        "create": {
            "ADD_FROM_CONNECTION": %addFromConnection%,
            "ADD_FROM_FILE_UPLOAD": %addFromFileUpload%,
            "SELECT_FILE_UPLOAD": %selectFileUpload%,
            "UPLOAD_NEW_FILE": %uploadnewFile%,
            "CREATE_JOINS": %createJoins%,
            "FILTER_VALUES_ENTITIES": %filterValuesEntities%,
        },
        "fields": {
            "ADD_DERIVED_FIELD": %addDerivedField%,
            "ADD_HIERARCHY_FIELD": %addHierarchyField%,
            "ADD_CUSTOM_METRIC": %addCustomMetric%,
            "VISIBILITY": %visibility%,
            "SETTINGS": %settings%,
            "DELETE": %delete%,
            "FIELD_CAPABILITIES": %fieldCapabilities%,
            "FILTER_VALUES_OVERRIDES": %filterValueOverrides%,
        },
        "caching": {
            "DATA_CACHE": %dataCache%,
            "STATISTICS_CACHE": %statisticsCache%,
            "SCHEDULE_REFRESH": %scheduleRefresh%,
        }
    },
    /* END: interactivity profile overrides */`;
