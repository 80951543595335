export const OPTIONS = {
  dashboard: {
    basicOptions: [{ key: 'dashboardEventListeners', defaultValue: true }],
    advancedOptions: [],
    eventListenerOptions: {
      Dashboard: [
        { key: 'dashboardChangedEventListener', defaultValue: false },
        { key: 'dashboardDeleteEventListener', defaultValue: false },
        { key: 'dashboardLoadFailEventListener', defaultValue: false },
        { key: 'dashboardReadyEventListener', defaultValue: false },
        { key: 'dashboardSaveEventListener', defaultValue: false },
        { key: 'dashboardVisualAddEventListener', defaultValue: false },
        { key: 'dashboardVisualRemoveEventListener', defaultValue: false },
      ],
      Visual: [
        { key: 'visualLoadEventListener', defaultValue: false },
        { key: 'visualLoadFailEventListener', defaultValue: false },
        { key: 'visualRenderEventListener', defaultValue: false },
        { key: 'visualSaveEventListener', defaultValue: false },
      ],
      Document: [
        { key: 'documentFailed', defaultValue: false },
        { key: 'unauthorized', defaultValue: false },
      ],
      VisualAuthoring: [
        { key: 'visualBuilderChange', defaultValue: false },
        { key: 'visualBuilderFail', defaultValue: false },
        { key: 'visualBuilderLoad', defaultValue: false },
        { key: 'visualBuilderReady', defaultValue: false },
      ],
    },
  },
  'visual-builder': {
    basicOptions: [{ key: 'visualEventListener', defaultValue: true }],
    advancedOptions: [],
    eventListenerOptions: {
      Visual: [
        { key: 'vbVisualLoadEventListener', defaultValue: false },
        { key: 'vbVisualLoadFailEventListener', defaultValue: false },
        { key: 'vbVisualRenderEventListener', defaultValue: false },
        { key: 'vbVisualSaveEventListener', defaultValue: false },
      ],
      VisualAuthoring: [
        { key: 'vbVisualBuilderChange', defaultValue: false },
        { key: 'vbVisualBuilderFail', defaultValue: false },
        { key: 'vbVisualBuilderReady', defaultValue: false },
      ],
    },
  },
  'dashboard-builder': {
    basicOptions: [{ key: 'dbEventListener', defaultValue: true }],
    advancedOptions: [],
    eventListenerOptions: {
      Dashboard: [
        { key: 'dashboardBuilderChangedEventListener', defaultValue: false },
        { key: 'dashboardBuilderDeleteEventListener', defaultValue: false },
        { key: 'dashboardBuilderLoadFailEventListener', defaultValue: false },
        { key: 'dashboardBuilderReadyEventListener', defaultValue: false },
        { key: 'dashboardBuilderSaveEventListener', defaultValue: false },
        { key: 'dashboardBuilderVisualAddEventListener', defaultValue: false },
        { key: 'dashboardBuilderVisualRemoveEventListener', defaultValue: false },
      ],
      Visual: [
        { key: 'dashboardBuilderVisualLoadEventListener', defaultValue: false },
        { key: 'dashboardBuilderVisualLoadFailEventListener', defaultValue: false },
        { key: 'dashboardBuilderVisualRenderEventListener', defaultValue: false },
        { key: 'dashboardBuilderVisualSaveEventListener', defaultValue: false },
      ],
      Document: [
        { key: 'dashboardBuilderDocumentFailed', defaultValue: false },
        { key: 'dashboardBuilderUnauthorized', defaultValue: false },
      ],
      VisualAuthoring: [
        { key: 'dbVisualBuilderChange', defaultValue: false },
        { key: 'dbVisualBuilderFail', defaultValue: false },
        { key: 'dbVisualBuilderLoad', defaultValue: false },
        { key: 'dbVisualBuilderReady', defaultValue: false },
      ],
    },
  },
  inventory: {
    basicOptions: [
      { key: 'inventoryEventListeners', defaultValue: true },
      { key: 'inventoryReadyEventListener', defaultValue: false },
      { key: 'inventoryFailEventListener', defaultValue: false },
      { key: 'inventoryDeleteEventListener', defaultValue: false },
    ],
    advancedOptions: [],
  },
  'source-editor': {
    basicOptions: [{ key: 'sourceEditorEventListeners', defaultValue: true }],
    advancedOptions: [],
  },
};
